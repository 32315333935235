import styled from "styled-components";
import React from "react"; 
import { Link } from "gatsby";

export const SinglePostArea = styled.section`
  padding-top: 60px;
  padding-bottom: 60px;
`;

export const ArticleImage = styled.img`
  width: 100%;
`;
export const StyledLink = styled((props) => <Link {...props} />)`
  display: block;
  text-align: center;
  color: black;
  padding-bottom: 12px;

  &:hover {
    color: grey;
  }
`;

export const ArticleMeta = styled.div`
  text-align: center;
  line-height: 1.8;
  padding-top: 5px;
`;

export const SinglePostWrap = styled.article`
padding-bottom:20px;
  h2 {
    color: #0e1b47;
    font-size: 24px;
    margin-top: 30px;
    margin-bottom: 10px;
    line-height: 1.4;
  }
  p {
    font-size: 14px;
    margin-bottom: 26px;
    line-height: 1.714;
    &:first-letter {
      text-transform: capitalize;
    }
  }
  blockquote {
    position: relative;
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
    text-align: center;
    p {
      font-size: 18px;
      font-weight: 500;
      line-height: 1.667;
      color: #0e1b47;
    }
    &:before {
      position: absolute;
      content: "";
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 125px;
      height: 109px;
      z-index: -1;
    }
  }
  figcaption {
    margin-top: 5px;
    text-align: center;
  }
  ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    margin-bottom: 20px;
    li {
      position: relative;
      font-size: 14px;
      color: #0e1b47;
      padding-left: 22px;
      padding-top: 2px;
      &:before {
        position: absolute;
        content: "";
        width: 6px;
        height: 6px;
        background: #99999c;
        border-radius: 50%;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  p {
    code {
      margin-top: 10px;
      display: block;
    }
  }
  img {
    border-radius: 5px;
  }
`;
