import * as React from "react";
import DefaultLayout from "../layouts/default";
import { SinglePostWrap } from "../components/LayoutComponents.stc";
import demo from "../assets/img/malanargia_galathea_schachbrett_haldenstein_070617_1.jpg";
import styled from "styled-components";
import { graphql } from "gatsby";
import { FamilieType, SeiteType } from "../components/types";
import { Col, Row } from "reactstrap";
import { StyledLink, ArticleImage, ArticleMeta } from "../components/LayoutComponents.stc";
import { Helmet } from "react-helmet";



const Content = styled.div`
  p,
  li {
    line-height: 25px;
  }
  img {
    max-width: 100%;
  }
`;


export const query = graphql`
query StartseitenQuery {
  cms {
    startseite: entry(slug: "startseite") {
      ... on CMS_startseite_startseite_Entry {
        htmlInhalt
        slug
      }
    }
    ordnungFamilie: categories(level: 1, group: "ordnungFamilien") {
      ... on CMS_ordnungFamilien_Category {
        id
        title
        slug
        bild {
          url
        }
      }
    }
  }
}

`;

interface IndexPageProps {
  pageContext: {
    slug: string;
  };
  data: {
    cms: {
      startseite: SeiteType;
      ordnungFamilie: FamilieType[];
    };
  };
}

const IndexPage: React.FC<IndexPageProps> = (props) => {
  const {
    data: {
      cms: {
        startseite: { htmlInhalt },
        ordnungFamilie
      },
    },
  } = props;


  let sliderItems:any = [];
  ordnungFamilie && ordnungFamilie.map((familie) => {
    familie.bild &&
      sliderItems.push(
          {
            src: familie.bild[0].url + "?w=1500",
            altText: familie.title,
          }
        )
      
    })
    
  return (
    <DefaultLayout sliderItems={sliderItems}>
      <Helmet
        title={"Startseite - Insects"}
        
      ></Helmet>
      <div dangerouslySetInnerHTML={{ __html: htmlInhalt! }}></div>
      <Row>
        { ordnungFamilie && ordnungFamilie.map((category) => {
        if(typeof(category.slug) != 'undefined'){
        return(<Col key={category.slug} xs="12" sm="6" md="4">
        <StyledLink to={"/kategorie/" + category.slug}>
          {category.bild && (<ArticleImage src={category.bild[0].url + "?w=530"} />)}
          <ArticleMeta>
            {category.title}
          </ArticleMeta>
        </StyledLink>
        </Col>)}})}
      </Row>


    </DefaultLayout>
  );
};

export default IndexPage;
